import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Microphone from 'assets/icons/microphone';
import { getAllVoices } from 'components/experience/readers/newsdk/setup';
import { VoiceCloningTestVariant } from 'constants/featureDefinitions';
import { FeatureNameEnum, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import { IUser } from 'interfaces';
import * as speechify from 'lib/speechify';
import { isPremium } from 'lib/speechify';
import { get } from 'lodash';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'store';
import { actions as personalVoicesActions } from 'store/personalVoices';
import { twMerge } from 'tailwind-merge';
import { useLocalStorage } from 'usehooks-ts';
import { getSource, logSegmentEvent } from 'utils/analytics';

import { VoiceSpecOfAvailableVoice } from '@speechifyinc/multiplatform-sdk';
import { Web, WebEntryPoint as UpsellModal } from '@speechifyinc/voice-cloning-web';

interface VoiceCloningProps {
  onComplete?: (voice: VoiceSpecOfAvailableVoice) => void;
  trigger: 'upsell-modal' | 'voice-list-banner';
}

export const VoiceCloning = ({ onComplete, trigger }: VoiceCloningProps) => {
  const dispatch = useDispatch();

  // ESLint: Include a description after the "@ts-expect-error" directive to explain why the @ts-expect-error is necessary
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const currentUser = useSelector<IUser>(state => state.auth.user);
  const personalVoices = useSelector(state => state.personalVoices.personalVoices);

  const portalContainerRef = useRef<HTMLDivElement | null>(null);

  // state
  const [idToken, setIdToken] = useState<string | null>(null);
  const [showVoiceCloning, setShowVoiceCloning] = useState(false);

  const [upsellLastClosedAt, setUpsellLastClosedAt] = useLocalStorage('vcw_upsell_last_closed_at', '0');

  // feature flags
  const voiceCloningVariant = useFeatureVariant(FeatureNameEnum.VOICE_CLONING);

  const isVoiceCloningEnabled = useMemo(() => {
    if (!isPremium(currentUser)) return false;

    if (personalVoices.length > 0 && currentUser?.email?.endsWith('@speechify.com')) {
      return true;
    }

    return isFeatureVariantReady(voiceCloningVariant) && voiceCloningVariant.variant === VoiceCloningTestVariant.ENABLED;
  }, [currentUser, personalVoices, voiceCloningVariant]);

  const showVoiceCloningUpgrade = useMemo(() => {
    return !isPremium(currentUser);
  }, [currentUser]);

  const showUpsellModal = useMemo(() => {
    if (showVoiceCloning) {
      return false;
    }

    if (trigger !== 'upsell-modal') {
      return false;
    }

    if (upsellLastClosedAt !== '0') {
      return false;
    }

    return true;
  }, [showVoiceCloning, trigger, upsellLastClosedAt]);

  useEffect(() => {
    if (showUpsellModal) {
      logSegmentEvent('web_app_voice_clone_prompt_shown', { source: 'library-screen' });
    }
  }, [showUpsellModal]);

  useEffect(() => {
    if (speechify.auth.currentUser?.uid) {
      speechify.auth.currentUser?.getIdToken().then(setIdToken);
    }
    // ESLint: React Hook useEffect has an unnecessary dependency: 'speechify.auth.currentUser.uid'. Either exclude it or remove the dependency array. Outer scope values like 'speechify.auth.currentUser.uid' aren't valid dependencies because mutating them doesn't re-render the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speechify.auth.currentUser?.uid]);

  useEffect(() => {
    if (!portalContainerRef.current) {
      const el = document.createElement('div');
      el.id = 'vcw-container';
      el.className = 'vcw';
      document.body.appendChild(el);
      portalContainerRef.current = el;
    }

    return () => {
      if (portalContainerRef.current) {
        if (document.body.contains(portalContainerRef.current)) {
          document.body.removeChild(portalContainerRef.current);
        }
      }
    };
  }, []);

  const refreshPersonalVoicesAndSetMostRecent = async (personalVoiceId?: string) => {
    dispatch(personalVoicesActions.refresh());

    const personalVoices = await speechify.getPersonalVoices();
    getAllVoices.invalidateCache();
    getAllVoices();

    if (personalVoices?.length > 0) {
      const newPersonalVoice = personalVoices.find(v => get(v, 'name') === 'PVL:' + personalVoiceId) || personalVoices.slice(-1)[0];
      onComplete?.(newPersonalVoice);
    }
  };

  const handleWebClose = () => {
    if (trigger !== 'upsell-modal') {
      setShowVoiceCloning(false);
    }
  };

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleWebComplete = (personalVoice: any) => {
    refreshPersonalVoicesAndSetMostRecent(personalVoice?.id);

    setTimeout(() => {
      setShowVoiceCloning(false);
    }, 250);
  };

  const handleUpsellModalClose = () => {
    setUpsellLastClosedAt(new Date().toISOString());
  };

  const handleUpsellModalContinue = () => {
    setUpsellLastClosedAt(new Date().toISOString());
    setShowVoiceCloning(true);
    logSegmentEvent('web_app_voice_clone_flow_initiated', { source: 'library-pop-up' });
  };

  const handleVoiceListBannerClick = () => {
    setShowVoiceCloning(true);
  };

  // entice free users to upgrade
  if (showVoiceCloningUpgrade && trigger === 'voice-list-banner') {
    return <VoiceListBanner isPremium={isPremium(currentUser)} onClick={handleVoiceListBannerClick} />;
  }

  // disabled feature
  if (!isVoiceCloningEnabled) return null;

  return (
    <>
      {trigger === 'voice-list-banner' && <VoiceListBanner isPremium={isPremium(currentUser)} onClick={handleVoiceListBannerClick} />}

      {showUpsellModal &&
        portalContainerRef.current &&
        ReactDOM.createPortal(<UpsellModal onClose={handleUpsellModalClose} onContinue={handleUpsellModalContinue} />, portalContainerRef.current)}

      {showVoiceCloning &&
        idToken &&
        portalContainerRef.current &&
        ReactDOM.createPortal(
          <Web
            email={currentUser.email || undefined}
            host="web-app"
            idToken={idToken}
            voicesServer={process.env.NEXT_PUBLIC_VOICES_SERVER}
            logAnalyticsEvent={logSegmentEvent}
            onComplete={handleWebComplete}
            onClose={handleWebClose}
          />,
          portalContainerRef.current
        )}
    </>
  );
};

interface VoiceListBannerProps {
  isPremium: boolean;
  onClick: () => void;
}

const VoiceListBanner = ({ isPremium, onClick }: VoiceListBannerProps) => {
  const router = useRouter();

  const handleClick = () => {
    if (isPremium) {
      onClick();
      logSegmentEvent('web_app_voice_clone_flow_initiated', { source: 'voice-list' });
    } else {
      window.open(speechify.getUpgradeURL('web_app_voice_clone_cta_upgrade'), '_blank');
      logSegmentEvent('web_app_dashboard_upgrade_clicked', { source: getSource(router.pathname), vcw: true });
    }
  };

  return (
    <div
      className="group relative flex items-center justify-between space-x-3 px-4 py-3 hover:bg-electric-200 dark:hover:bg-glass-800 cursor-pointer border-b border-glass-300 dark:border-glass-600"
      onClick={handleClick}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <div
            className={twMerge(
              'size-10 rounded-full flex items-center justify-center mr-3',
              isPremium ? 'bg-electric-200 group-hover:bg-electric-250' : 'bg-yellow-200'
            )}
          >
            <Microphone className={twMerge(isPremium ? 'text-electric-350' : 'text-yellow-400')} />
          </div>
        </div>
        <div className="min-w-0 flex-1">
          <div className="focus:outline-none">
            <div className="flex items-center">
              <span className="absolute inset-0" aria-hidden="true"></span>
              <p className="flex items-center font-ABCDiatype text-sm font-bold tracking-wide text-glass-700 opacity-100 dark:text-glass-0">
                <span className="mr-1 whitespace-nowrap">Create your own voice</span>
              </p>
            </div>
            <div className="flex items-center truncate font-ABCDiatype text-sm font-normal tracking-wide text-glass-500 dark:text-glass-350">
              In less than a minute
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className={twMerge(
          'flex-shrink-0 px-3 py-2 text-sm font-medium focus:outline-none rounded-lg',
          isPremium ? 'text-white bg-electric-400 hover:bg-electric-500' : 'text-glass-700 bg-yellow-400'
        )}
      >
        {isPremium ? 'Create' : 'Upgrade'}
      </button>
    </div>
  );
};

export default VoiceCloning;
